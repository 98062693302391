import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';
import CustomerReviewCard from '../../atoms/CustomerReviewCard';
import SliderNextButton from '../../atoms/SliderNextButton';
import SliderPrevButton from '../../atoms/SliderPrevButton';
import { connect } from 'react-redux';

const ReviewCardSliderLG = (props) => {
  const settings = {
    autoPlay: 'true',
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    // infinite: true,
    dots: false,
    fade: true,
    arrows: true,
    nextArrow: <SliderNextButton />,
    prevArrow: <SliderPrevButton />,
    className: 'react__slick__slider__parent',
  };
  const [reviewCardData, setReviewCardData] = React.useState(null);

  React.useEffect(() => {
    const data = props.entries.filter(
      (entry) => entry.sys.contentType.sys.id === 'testimonial'
    );
    setReviewCardData(
      data.map((item) => {
        return {
          author: item.fields.customerName,
          content: item.fields.text,
          imagePath: item.fields.image.fields.file.url,
          title: item.fields.title,
        };
      })
    );
    // setReviewCardData(data);
  }, []);

  if (reviewCardData === null) {
    return <></>;
  }
  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Slider {...settings}>
        {(reviewCardData as any).map((item: any, i: number) => (
          <Box p="5px" key={i}>
            <CustomerReviewCard {...item} isSlider />
          </Box>
        ))}
      </Slider>
      {/* <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          zIndex: '100',
          justifyContent: 'between',
        }}
      >
        <SliderPrevButton />
        <SliderNextButton />
      </Box> */}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};
export default connect(mapStateToProps, null)(ReviewCardSliderLG);
