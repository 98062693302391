import React from 'react';

import Text from '../Text';
import Button from '../Button';
import ContactButton from '../ContactButton';
import Testimonials from '../Testimonials';
import AccordionList from '../AccordionList';
import Form from '../Form';
import MessageComponent from '../MessageComponent';
import ContactList from '../ContactList';
import {
  Box,
  Container
} from '@mui/material';
import YouTubeVideo from "../YouTubeVideo";
import LandingPageBlock from "../LandingPageBlock";
import LandingHouseCardSlider from "../../../components/molecules/LandingHouseCardSlider";
import LandingOffer from "../../../components/molecules/LandingOffer";
import LandingSellHome from "../../../components/molecules/LandingSellHome";
import TwoColumn from "../TwoColumn";
import RowBlock from "../RowBlock";
const ComponentContentTypes = {
  TEXT: 'text',
  ACCORDIONLIST: 'accordionList',
  FORM: 'form',
  BUTTON: 'button',
  CONTACTBUTTON: 'contactButton',
  MESSAGECOMPONENT: 'ctaBlock',
  CONTACTLIST: 'contactList',
  TESTIMONIALS: 'testamonials',
  YOUTUBE_VIDEO: 'youTubeVideo',
  LANDING_PAGE_BLOCK: 'landingPageBlock',
  OBJECT_LIST: 'objectList',
  SERVICE_LIST: 'serviceList',
  TWO_COLUMN: 'twoColumn',
  TWO_COLUMN_1_6_1: 'twoColumn_1_6-1',
  ROW_BLOCK: 'rowBlock'
};

type ContentProps = {
  data: any;
};

const Content = ({ data }: ContentProps) => {
  if (Array.isArray(data)) {
    return (
      <>
        {data.map((item) => (
          <Content
            key={`block-${(Math.random() + 1).toString(36).substring(7)}`}
            data={item}
          />
        ))}
      </>
    );
  }

  const contentTypeId = data.sys.contentType.sys.id;
  const Component = ContentTypeMap[contentTypeId];

  if (!Component) {
    console.warn(`${contentTypeId} can not be handled`);
    return null;
  }

  const { id } = data.sys;

  const componentProps = {
    data: id,
  };

  const componentHeights = {
    [ComponentContentTypes.SERVICE_LIST]: {
      xs: 568,
      lg: 568
    },
    [ComponentContentTypes.OBJECT_LIST]: {
      lg: 595,
      xs: 408
    }
  };

  const xs = componentHeights[contentTypeId] ? componentHeights[contentTypeId].xs : null;
  const lg = componentHeights[contentTypeId] ? componentHeights[contentTypeId].lg : null;

  return (
    <div className={contentTypeId}>
      <Box
        sx={xs && lg ? {
        minHeight: {
            xs: `${xs}px`,
            lg: `${lg}px`
          }
        }:{}}>
        <Component key={`${contentTypeId}-${id}`} {...componentProps} />
      </Box>
    </div>
  );
};

const ContentTypeMap = {
  [ComponentContentTypes.TEXT]: Text,
  [ComponentContentTypes.ACCORDIONLIST]: AccordionList,
  [ComponentContentTypes.FORM]: Form,
  [ComponentContentTypes.BUTTON]: Button,
  [ComponentContentTypes.CONTACTBUTTON]: ContactButton,
  [ComponentContentTypes.MESSAGECOMPONENT]: MessageComponent,
  [ComponentContentTypes.CONTACTLIST]: ContactList,
  [ComponentContentTypes.TESTIMONIALS]: Testimonials,
  [ComponentContentTypes.YOUTUBE_VIDEO]: YouTubeVideo,
  [ComponentContentTypes.LANDING_PAGE_BLOCK]: LandingPageBlock,
  [ComponentContentTypes.OBJECT_LIST]: LandingHouseCardSlider,
  [ComponentContentTypes.SERVICE_LIST]: LandingOffer,
  [ComponentContentTypes.TWO_COLUMN]: TwoColumn,
  [ComponentContentTypes.TWO_COLUMN_1_6_1]: LandingSellHome,
  [ComponentContentTypes.ROW_BLOCK]: RowBlock
};

export default Content;
