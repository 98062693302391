import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {useSelector} from "react-redux";
import Content from '../Content';

const TwoColumn = props => {
  const {data} = props;
  const [entry, setEntry] = useState();
  const [left, setLeft] = useState();
  const [right, setRight] = useState();
  const entries = useSelector(state => state.main.entries);

  React.useEffect(() => {
    const thisEntry = entries.find(e => e.sys.id === data);
    setEntry(thisEntry)
    setLeft(entries.find(e => e.sys.id === thisEntry.fields.left.sys.id))
    setRight(entries.find(e => e.sys.id === thisEntry.fields.right.sys.id))
  }, []);

  if (!entry || !left || !right) return null;

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          gap: "1rem",
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        <Content
          key={`block-${(Math.random() + 1).toString(36).substring(7)}`}
          data={left}
        />
        <Content
          key={`block-${(Math.random() + 1).toString(36).substring(7)}`}
          data={right}
        />
      </Box>
    </Container>
  );
};
export default TwoColumn;
