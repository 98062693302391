import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { connect } from 'react-redux';
import { Typography, Box } from '@mui/material';
import MediaComponent from "../MediaComponent";
import YouTubeVideo from "../YouTubeVideo";

interface TextProps {
  data: string;
  entries: any[];
  isAccordion?: boolean;
}

const Text = (props: TextProps) => {
  const { data, entries } = props;
  const [textContent, setTextContent] = React.useState(null);

  const removeSharp = (children) => {
    const data = children;
    data[0] = data[0].replaceAll('#', '');
    data[data.length - 1] = data[data.length - 1].replaceAll('#', '');
    return data;
  };

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        switch (node.data.target.sys.contentType.sys.id) {
          case "mediaComponent":
            return (
              <Box
                width={node.data.target.fields.width}
                maxWidth={"100%"}
                className={node.data.target.fields.classes}>
                <MediaComponent
                  imagePath={node.data.target.fields.media.fields.file.url}
                  width={node.data.target.fields.media.fields.file.details.image.width}
                  height={node.data.target.fields.media.fields.file.details.image.height}
                  alt={node.data.target.fields.media.fields.description}
                />
              </Box>
            )
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if ((children[0] as string)[0] === '#')
          return (
            <Typography
              component="p"
              sx={{
                fontFamily: 'Lato',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
                marginBottom: "1rem"
              }}
            >
              {removeSharp(children)}
            </Typography>
          );

        return (
          <Typography
            component="p"
            sx={{
              fontFamily: 'Lato',
              fontWeight: '400',
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: "1rem"
            }}
          >
            {children}
          </Typography>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography
          component="h1"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '38px',
            lineHeight: '57px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography
          component="h2"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '54px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography
          component="h3"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '51px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography
          component="h4"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '32px',
            lineHeight: '48px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography
          component="h5"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '30px',
            lineHeight: '45px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography
          component="h6"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '28px',
            lineHeight: '42px',
            py: '8px',
          }}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          if (!node.data.target.fields) return null;

          if (node.data.target.fields.youTubeVideoId) {
              return (
                  <YouTubeVideo
                      data={node.data.target.sys.id}/>
              )
          }

          // Image
          if (node.data.target.fields.media) {
              return (
                  <MediaComponent
                      imagePath={node.data.target.fields.media.fields.file.url}
                      width={node.data.target.fields.media.fields.file.details.image.width}
                      height={node.data.target.fields.media.fields.file.details.image.height}
                      alt={node.data.target.fields.media.fields.description}
                  />
              )
          }
      }
    },
    renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  };

  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data).fields;
    const textContent = res.content;

    setTextContent(textContent);
  }, []);
  return (
    <>
      <Box
        sx={{
          maxWidth: props.isAccordion === true ? '1200px' : '900px'
        }}
      >
        {documentToReactComponents(textContent, options)}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(Text);
