import React from 'react';

import Text from '../Text';
import LandingPageBlock from '../LandingPageBlock';

const ComponentContentTypes = {
  TEXT: 'text',
  LANDING_PAGE_BLOCK: 'landingPageBlock',
};

type ContentProps = {
  data: any;
  location: string;
};

const Content = ({ data, location }: ContentProps) => {
  if (Array.isArray(data)) {
    return (
      <>
        {data.map((item) => (
          <Content
            key={`block-${(Math.random() + 1).toString(36).substring(7)}`}
            data={item}
            location={location}
          />
        ))}
      </>
    );
  }

  const contentTypeId = data.sys.contentType.sys.id;
  const Component = ContentTypeMap[contentTypeId];

  if (!Component) {
    console.warn(`${contentTypeId} can not be handled`);
    return (
      <div
        className=""
        style={{ border: '1px solid', margin: '3px', padding: '3px' }}
      >
        This component - "{contentTypeId}" can't be handled yet.
      </div>
    );
  }

  const { id } = data.sys;

  const componentProps = {
    data: id,
    location: location,
  };

  return <Component key={`${contentTypeId}-${id}`} {...componentProps} />;
};

const ContentTypeMap = {
  [ComponentContentTypes.TEXT]: Text,
  [ComponentContentTypes.LANDING_PAGE_BLOCK]: LandingPageBlock,
};

export default Content;
