import React from 'react';
import {
  Button as MUIButton,
  Typography,
  Box,
  Container,
  Grid,
  Button,
} from '@mui/material';
import Link from 'next/link';
import { connect } from 'react-redux';

import LandingHouseCardSlider from '../../../components/molecules/LandingHouseCardSlider';
import LandingReviewCardSlider from '../../../components/molecules/LandingReviewCardSlider';
import ContactList from '../ContactList';

import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useRouter } from 'next/router';
import BlackButton from '../../../components/atoms/BlackButton';
import ContactButton from "../ContactButton";

interface LandingPageBlockProps {
  data: string; // entry id of button
  location: string;
  entries: any[];
}

const LandingPageBlock = (props: LandingPageBlockProps) => {
  const { entries, location, data } = props;

  const [blockType, setBlockType] = React.useState('');
  const router = useRouter();
  const slug = router.asPath;
  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data).fields;
    const blockType = res.type;
    setBlockType(blockType);
  }, []);

  if (blockType === '') return <></>;

  let content;
  switch (blockType) {
    case 'Share': {
      content = (
        <>
          <Container>
            <Box sx={{ display: 'flex', my: 4 }}>
              <Typography sx={{ mr: 3 }}>TIPSA</Typography>
              <Link
                href={`mailto:?subject=${encodeURIComponent(`Privatmäklaren: ${location}`)}&body=https://www.privatmaklaren.se${router.asPath}`}
                passHref
                style={{ textDecoration: 'none', color: 'inherit' }}>

                <MailIcon sx={{ mr: 3, cursor: 'pointer' }} />

              </Link>
              {/* <Link href={`http://www.facebook.com/sharer.php?u=https://www.privatmaklaren.se${slug}`} target="_blank" passHref> */}
              <a
                rel="noreferrer"
                href={`http://www.facebook.com/sharer.php?u=${process.env.NEXT_PUBLIC_DOMAIN_URL}${slug}`}
                target="_blank"
                data-href="https://www.privatmaklaren.se/stockholm"
                data-title="Stockholm"
                data-summary={`Våra mäklare hjälper dig till en lyckad bostadsaffär ${location}! Vår framgång bygger på vår väl beprövade försäljningsprocess. Tack vare vårt fokus på modern teknik, kunskap och service levererar vi lyckade bostadsaffärer till våra kunder.`}
                data-image="https://www.privatmaklaren.se/images/LogoImage.svg"
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <FacebookIcon sx={{ mr: 3, cursor: 'pointer' }} />
              </a>
              {/* </Link> */}
              <a
                rel="noreferrer"
                href={`https://twitter.com/intent/tweet?text=${location}&url=${process.env.NEXT_PUBLIC_DOMAIN_URL}${slug}&via=Privatmaklaren`}
                target="_blank"
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <TwitterIcon sx={{ mr: 3, cursor: 'pointer' }} />
              </a>
              <a
                rel="noreferrer"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.NEXT_PUBLIC_DOMAIN_URL}${slug}&title=${location}&summary=Våra mäklare hjälper dig till en lyckad bostadsaffär ${location}! Vår framgång bygger på vår väl beprövade försäljningsprocess. Tack vare vårt fokus på modern teknik, kunskap och service levererar vi lyckade bostadsaffärer till våra kunder. Privatmäklaren din digitala mäklare ${location} `}
                target="_blank"
                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <LinkedInIcon sx={{ mr: 3, cursor: 'pointer' }} />
              </a>
            </Box>
          </Container>
        </>
      );
      break;
    }
    case 'Services': {
      content = (
        <>
          <Container maxWidth="lg" sx={{ my: 4 }}>
            <Typography
              component="p"
              sx={{
                fontSize: { xs: '16px', lg: '24px' },
                marginBottom: '20px',
                marginTop: { xs: '27px', lg: '70px' },
                marginLeft: { xs: '16px', lg: '52px' },
                textTransform: 'uppercase',
                fontFamily: 'Avenir-Bold',
              }}
            >
              {'VAD VI ERBJUDER ' + location}
            </Typography>
          </Container>
          <Box
            sx={{
              backgroundColor: { lg: 'rgba(242, 244, 247, 0.8)', xs: '#FFF' },
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: { xs: '30px', lg: '55px' },
                    // '&:hover': { backgroundColor: 'rgba(232, 234, 255, 1)' },
                    // cursor: 'pointer',
                    // transition: 'all 0.4s ease-in-out',
                  }}
                >
                  <Box
                    component="img"
                    src="/images/g1.svg"
                    alt="g1"
                    sx={{
                      mt: '30px',
                      width: { xs: '95px', md: '210px' },
                      height: { xs: '95px', md: '210px' },
                    }}
                  />
                  <Typography
                    component="p"
                    className="font-Avenir-Bold"
                    sx={{
                      fontSize: { xs: '18px', lg: '30px' },
                      marginBottom: { xs: '11px', lg: '20px' },
                      marginTop: { xs: '18px', lg: '40px' },
                      textTransform: 'uppercase',
                    }}
                  >
                    Mäklare
                  </Typography>
                  <Typography
                    component="p"
                    className="font-Lato-Bold"
                    sx={{
                      fontSize: { xs: '14px', lg: '18px' },
                    }}
                  >
                    {`Digital mäklartjänst. Ett personligare och flexiblare sätt att sälja din bostad. Du sköter visningen och vi ordnar resten. Tillsammans säljer vi din bostad bättre än någon annan. Samtidigt betalar du det lägsta mäklararvodet ${location}.`}
                  </Typography>

                  <Box mt={3} />
                  <BlackButton
                    link="/salja#maklare"
                    width="190px"
                    height="60px"
                    name="Läs mer"
                    fontSize="17px"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: { xs: '30px', lg: '55px' },
                    // '&:hover': { backgroundColor: 'rgba(232, 234, 255, 1)' },
                    // cursor: 'pointer',
                    // transition: 'all 0.4s ease-in-out',
                  }}
                >
                  <Box
                    component="img"
                    src="/images/g2.svg"
                    alt="g1"
                    sx={{
                      mt: '30px',
                      width: { xs: '95px', md: '210px' },
                      height: { xs: '95px', md: '210px' },
                    }}
                  />
                  <Typography
                    component="p"
                    className="font-Avenir-Bold"
                    sx={{
                      fontSize: { xs: '18px', lg: '30px' },
                      marginBottom: { xs: '11px', lg: '20px' },
                      marginTop: { xs: '18px', lg: '40px' },
                      textTransform: 'uppercase',
                    }}
                  >
                    Kontrakt
                  </Typography>
                  <Typography
                    component="p"
                    className="font-Lato-Bold"
                    sx={{
                      fontSize: { xs: '14px', lg: '18px' },
                    }}
                  >
                    {`Har du redan en köpare till din bostad? Behöver du bara hjälp med köpekontrakt och övriga handlingar. Då passar vår tjänst för köpekontrakt ${location} perfekt för dig. Anlita våra mäklare för ett skrivuppdrag ${location}.`}
                  </Typography>

                  <Box mt={3} />
                  <BlackButton
                    link="/salja#kontrakt"
                    width="190px"
                    height="60px"
                    name="Läs mer"
                    fontSize="17px"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: { xs: '30px', lg: '55px' },
                    // '&:hover': { backgroundColor: 'rgba(232, 234, 255, 1)' },
                    // cursor: 'pointer',
                    // transition: 'all 0.4s ease-in-out',
                  }}
                >
                  <Box
                    component="img"
                    src="/images/g3.svg"
                    alt="g1"
                    sx={{
                      mt: '30px',
                      width: { xs: '95px', md: '210px' },
                      height: { xs: '95px', md: '210px' },
                    }}
                  />
                  <Typography
                    component="p"
                    className="font-Avenir-Bold"
                    sx={{
                      fontSize: { xs: '18px', lg: '30px' },
                      marginBottom: { xs: '11px', lg: '20px' },
                      marginTop: { xs: '18px', lg: '40px' },
                      textTransform: 'uppercase',
                    }}
                  >
                    Annons
                  </Typography>
                  <Typography
                    component="p"
                    className="font-Lato-Bold"
                    sx={{
                      fontSize: { xs: '14px', lg: '18px' },
                    }}
                  >
                    {`Vill du sälja din bostad ${location} utan mäklare? Då kan du använda vår tjänst för bostadsannonsering. Med vår annonstjänst för privatsäljare får du en annons på Sveriges näst största annonsplats för bostäder - Booli.`}
                  </Typography>
                  <Box mt={3} />
                  <BlackButton
                    link="/salja#annons"
                    width="190px"
                    height="60px"
                    name="Läs mer"
                    fontSize="17px"
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      );
      break;
    }
    case 'Objects': {
      content = (
        <Box sx={{ my: 4 }}>
          <LandingHouseCardSlider />
        </Box>
      );
      break;
    }
    case 'Testimonials': {
      content = (
        <Box sx={{ my: 4 }}>
          <LandingReviewCardSlider />
        </Box>
      );
      break;
    }
    case 'Agents': {
      content = <ContactList data="7zuf7mdcAgJVaOhLh0479u" />;
      break;
    }
    case 'Sales Message': {
      content = (
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              my: { xs: '0px', md: '63px' },
              height: { md: '394px', xs: '180px' },
              backgroundColor: {
                xs: 'rgba(131, 184, 152, 1)',
                md: 'rgba(49, 57, 76, 1)',
              },
            }}
          >
            <Typography
              component="p"
              className="font-Lato-Bold"
              sx={{
                fontSize: { xs: '16px', md: '36px' },
                color: { md: 'white', xs: 'black' },
              }}
            >
              {`Funderar du på att sälja din bostad ${location}?`}
            </Typography>
            <Typography
              component="p"
              className="font-Lato-Regular"
              sx={{
                fontSize: { xs: '14px', md: '24px' },
                marginTop: { md: '55px', xs: '9px' },
                marginBottom: { md: '72px', xs: '22px' },
                color: { md: 'white', xs: 'black' },
              }}
            >
              {`Låt oss kontakta dig för en fri rådgivning.`}
            </Typography>
            <ContactButton title={"Kontakt"}></ContactButton>
          </Box>
        </Container>
      );
      break;
    }
    case 'Partner Logos': {
      content = (
        <Box
          sx={{
            backgroundColor: '#FFF',
          }}
        >
          <Box
            component="i"
            sx={{
              textAlign: 'center',
              fontSize: '24px',
              display: 'block',
              fontFamily: 'Avenir',
              marginTop: '16px',
            }}
          >
            I samarbete med:
          </Box>
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              mb: '32px',
            }}
          >
            <Box
              sx={{
                width: { md: '33%', xs: '100%' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="images/Hemnet_Logo_RGB_svg.svg"
                alt="hemnet"
                width="230px"
                style={{ marginTop: '-20px', marginBottom: '-10px' }}
              />
            </Box>
            <Box
              sx={{
                width: { md: '33%', xs: '100%' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="images/booli.svg"
                alt="hemnet"
                width="150px"
                style={{ marginBottom: '10px' }}
              />
            </Box>
            <Box
              sx={{
                width: { md: '33%', xs: '100%' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src="images/bovision-logo.svg" alt="hemnet" width="180px" />
            </Box>
          </Container>
        </Box>
      )
    }
  }
  return <>{content}</>;
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(LandingPageBlock);
