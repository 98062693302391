import React, {useState} from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
} from '@mui/material';
import ContactButton from '../ContactButton';
import Button from '../Button';
import { BLOCKS } from '@contentful/rich-text-types';

interface MessageComponentProps {
  data: string;
  entries: any[];
}

const MessageComponent = (props: MessageComponentProps) => {
  const { data, entries } = props;

  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState(null);
  const [btn, setBtn] = useState<any>();
  const [style, setStyle] = useState();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if ((children[0] as string)[0] === '#')
          return (
            <Typography
              component="p"
              sx={{
                fontFamily: 'Lato',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
              }}
              p={1}
            >
              {(children[0] as string).slice(1, -1)}
            </Typography>
          );
        return (
          <Typography
            component="p"
            sx={{
              fontFamily: 'Lato',
              fontWeight: '400',
              fontSize: '20px',
              lineHeight: '24px',
            }}
            p={1}
          >
            {children[0] as string}
          </Typography>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography
          component="h1"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '38px',
            lineHeight: '56px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography
          component="h2"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '54px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography
          component="h3"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '51px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography
          component="h4"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '32px',
            lineHeight: '48px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography
          component="h5"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '30px',
            lineHeight: '45px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography
          component="h6"
          sx={{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '28px',
            lineHeight: '42px',
            px: '4px',
            py: '8px',
          }}
        >
          {children[0] as string}
        </Typography>
      ),
    },
  };

  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data);

    setTitle(res.fields.title);
    setMessage(res.fields.message.content);
    setStyle(res.fields.style);
    if (res.fields.callToAction.sys.id) {
        const entry = entries.find(
            (entry) => entry.sys.id === res.fields.callToAction.sys.id
        );
        setBtn(entry);
    }
  }, []);

  if (!btn) return null;

  let button;

  switch (btn.sys.contentType.sys.id) {
    case "button":
      button = (<Button data={btn.sys.id}/>);
      break;
    case "contactButton":
      button = (<ContactButton title={btn.fields.title} />);
      break;
  }

  let bgColor;
  let color;

  switch (style) {
    case 'dark-blue-light-blue':
      bgColor = 'inherit';
      color = "#31394B";
      break;
    case 'light-green-dark-blue':
      bgColor = '#82B897';
      color = '#31394B';
      break;
    case 'gray-light-blue':
      bgColor = '#BCCAD7';
      color = 'rgba(49, 57, 76, 1)';
      break;
    case 'white-dark-blue':
      bgColor = '#31394B';
      color = '#fff';
      break;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          my: { xs: '30px', md: '63px' },
          height: { md: '394px', xs: '394px' },
          backgroundColor: bgColor,
          color: color,
          px: 2,
        }}
      >
        <Typography
          component="p"
          className="font-Lato-Bold"
          sx={{
            fontSize: { xs: '16px', md: '36px' }
          }}
          textAlign="center"
        >
          {title}
        </Typography>

        <Typography
          component="p"
          className="font-Lato-Regular"
          sx={{
            fontSize: { xs: '14px', md: '24px' },
            marginTop: { md: '55px', xs: '9px' },
            marginBottom: { md: '72px', xs: '22px' },
            textAlign: "center"
          }}
        >
          {/* {message} */}
          {message && message[0].content[0].value}
        </Typography>
        {button}
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(MessageComponent);
