import React, { ReactNode } from 'react';
import Hero from '../Hero';
import CustomerMessage from '../CustomerMessage';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Box, Container } from '@mui/material';
import LandingSellHome from '../../../components/molecules/LandingSellHome';
import LandingAboutCards from '../../../components/molecules/LandingAboutCards';
import LandingOffer from '../../../components/molecules/LandingOffer';
import LandingReviewCardSlider from '../../../components/molecules/LandingReviewCardSlider';
import LandingHouseCardSlider from '../../../components/molecules/LandingHouseCardSlider';
import SaljaContent from '../../../components/organs/SaljaContent';
import Content from '../Content';
import Content4LP from '../Content4LP';
import LandingPageBlock from "../LandingPageBlock";

const Page = (props) => {
  const { data, isLp } = props;
  const slug = data.fields.slug ?? '';

  let pageContent: ReactNode;
  switch (slug) {
    case 'salja':
      pageContent = <SaljaContent />;
      break;
    default:
      pageContent =
        isLp === true ? (
          <>
            <Container>
              <Content4LP
                data={data.fields.content}
                location={data.fields.metaTitle.slice(
                  8,
                  data.fields.metaTitle.indexOf('|') - 1
                )}
              />
            </Container>
          </>
        ) : (
          <>
            <Container>
              <Content data={data.fields.content} />
            </Container>
          </>
        );
      break;
  }

  return (
    <>
      <Box sx={{ width: '100%', position: 'fixed', zIndex: '20', top: '0px' }}>
        <CustomerMessage bgColor="gray" />
        <Navbar />
      </Box>
      <Box
        sx={{
          mt: { xs: '60px', lg: '80px' },
        }}
      >
        <Hero
          heroImage={data.fields.heroImage ? data.fields.heroImage : null}
          heroTitle={data.fields.heroTitle ? data.fields.heroTitle : null}
          heroText={data.fields.heroText ? data.fields.heroText : null}
          heroButtons={data.fields.heroButtons ? data.fields.heroButtons : null}
        />
      </Box>
      {pageContent}
      <Footer />
    </>
  );
};

export default Page;
