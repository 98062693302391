import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import ReviewCardSliderXS from './ReviewCardSliderXS';
import ReviewCardSliderLG from './ReviewCardSliderLG';

import useWidth from '../../../utils/useWidth';

const ResponsiveComponent = () => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' ? (
        <ReviewCardSliderLG />
      ) : (
        <ReviewCardSliderXS />
      )}
    </>
  );
};
const LandingReviewCardSlider = () => {
  return (
    <Box
      px={0}
      pb={2}
      sx={{ backgroundColor: '#F2F4F7FF', paddingTop: '32px' }}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          sx={{
            fontSize: { xs: '16px', lg: '24px' },
            marginBottom: '20px',
            // marginTop: { xs: '24px', lg: '32px' },
            // marginLeft: { xs: '16px', lg: '52px' },
            textTransform: 'uppercase',
            fontFamily: 'Avenir-Bold',
          }}
        >
          Kundomdömen
        </Typography>
        <Box px="15px" pt="22px" sx={{ width: '100%' }}>
          <Box sx={{ zIndex: '20' }}>
            <ResponsiveComponent />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingReviewCardSlider;
